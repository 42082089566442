.guide-card-link {
  text-decoration: none;
  color: inherit;
}

.guide-card-wrapper {
  width: 140px;
  white-space: normal;
}

.guide-card-image {
  width: 140px; 
  height: 140px;
  border-radius: 10px;
}

.guide-card-row-small {
  padding: 8px 0px;
}

.guide-card-row {
  width: 150px;
}

.guide-card-row:hover {
  background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25));
}

.explorePage-guideCardTitleContainer {
  max-width: 90%;
  font-size: 15px;
  font-family: 'Public Sans', sans-serif;
  font-weight: 600;
  color: #686868;
}

.explorePage-guideCardTitleContainerContainer {
  justify-content: center;
  margin-top: 8px;
}